"use client";

// Next
import Link from "next/link";
import { useTranslations } from "next-intl";

// React Icons
import { HiOutlineAdjustments, HiViewGrid } from "react-icons/hi";
import { HiOutlineLightBulb } from "react-icons/hi2";

// Components
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter,
} from "@/components/ui/card";
import Footer from "@/components/ui/footer";
import Navbar from "@/components/ui/Navbar";
import Logo from "@/components/ui/Logo";

// Utils
import { getFQCN } from "@/lib/helpers/fqcn/methods";
import { Button } from "@/components/ui/button";

const arrayNav = [
  {
    title: "Config prototype",
    desc: "Access listing and manage configs like tenants, hostnames",
    link: "task-manager",
    icon: <HiViewGrid className="w-10 h-10 text-blue-500" />,
    id: "config",
  },
  {
    title: "Back-end status",
    desc: "Access status of the API Endpoints, generate default tokens",
    link: "status",
    icon: <HiOutlineAdjustments className="w-10 h-10 text-green-500" />,
    id: "back-end",
  },
];

var fqcn_bui = { Bundle: "homeBundle", Unit: "landing", Interface: "landing" };

export default function Landing({
  params: { locale },
}: {
  params: { locale: string };
}) {
  const t = useTranslations("Landing");

  return (
    <main id={getFQCN(fqcn_bui, "main-landing")} className="dark:bg-dark-mode">
      <article className="max-w-screen flex flex-col min-h-screen h-full justify-between items-center">
        <div className="pb-5">
          <div className="text-center pt-5">
            <h1
              id={getFQCN(fqcn_bui, "title-landing")}
              className="text-2xl font-bold"
            >
              {t("title")}
            </h1>
            <p id={getFQCN(fqcn_bui, "subtitle-landing")}>{t("subtitle")}</p>
          </div>

          <div className=" pt-12 grid grid-cols-1 gap-8 md:grid-cols-3 md:p-12 justify-center place-items-center">
            {arrayNav.map((item) => (
              <div className="flex w-60 h-64" key={arrayNav.indexOf(item)}>
                <Link
                  href={
                    locale === "fr" ? `/fr/${item.link}` : `/en/${item.link}`
                  }
                >
                  <div className="flex w-60 h-64">
                    <Card
                      id={getFQCN(fqcn_bui, item.id + "-card")}
                      className="text-center border-gray-300 hover:border-blue-400 transform hover:scale-[1.02] transition-all ease-linear"
                    >
                      <CardHeader
                        id={getFQCN(fqcn_bui, item.id + "-cardHeader")}
                        className="flex justify-center items-center"
                      >
                        {item.icon}
                      </CardHeader>
                      <CardTitle id={getFQCN(fqcn_bui, item.id + "-cardTitle")}>
                        {" "}
                        {t(item.title)}
                      </CardTitle>
                      <CardContent
                        id={getFQCN(fqcn_bui, item.id + "-cardContent")}
                        className="text-sm p-3"
                      >
                        {t(item.desc)}
                      </CardContent>
                      <CardFooter
                        id={getFQCN(fqcn_bui, item.id + "-cardFooter")}
                        className="flex justify-center items-center"
                      >
                        <div className="fixed bottom-0 mb-4">
                          <Button className="bg-phpc-blue dark:bg-phpc-blue-dark hover:bg-phpc-blue-dark dark:hover:bg-phpc-blue text-white">
                            {t("Visit")}
                          </Button>
                        </div>
                      </CardFooter>
                    </Card>
                  </div>
                </Link>
              </div>
            ))}
            <div className="flex w-60 h-64" key={2}>
              <Link href="https://dev.react-doc.phpr.link/">
                <div className="flex w-60 h-64">
                  <Card
                    id={getFQCN(fqcn_bui, "doc" + "-card")}
                    className="text-center border-gray-300 hover:border-blue-400 transform hover:scale-[1.02] transition-all ease-linear"
                  >
                    <CardHeader
                      id={getFQCN(fqcn_bui, "doc" + "-cardHeader")}
                      className="flex justify-center items-center"
                    >
                      <HiOutlineLightBulb className="w-10 h-10 text-yellow-500" />
                    </CardHeader>
                    <CardTitle id={getFQCN(fqcn_bui, "doc" + "-cardTitle")}>
                      {" "}
                      {t("Documentation")}
                    </CardTitle>
                    <CardContent
                      id={getFQCN(fqcn_bui, "doc" + "-cardContent")}
                      className="text-sm p-3"
                    >
                      {t(
                        "Dive into the full documentation from components, tech stack, tests"
                      )}
                    </CardContent>
                    <CardFooter
                      id={getFQCN(fqcn_bui, "doc" + "-cardFooter")}
                      className="flex justify-center items-center"
                    >
                      <div className="fixed bottom-0 mb-4">
                        <Button className="flex bg-phpc-blue dark:bg-phpc-blue-dark hover:bg-phpc-blue-dark dark:hover:bg-phpc-blue text-white">
                          {t("Visit")}
                        </Button>
                      </div>
                    </CardFooter>
                  </Card>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </article>
    </main>
  );
}
